import staticFilePaths from 'constant/static-file';
import { css, styled } from 'styles';
import typography from 'styles/typography';

export const CardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$white',
  borderRadius: 12,
  maxWidth: 768,
  width: '100%',
  boxShadow:
    'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
  px: 16,
  py: 32,
  margin: 'auto',
  '@md': {
    px: 48,
    py: 48,
  },
  marginTop: 16,
  marginBottom: 16,
});

export const ALinkMenu = styled('a', {
  ...typography.body2,
  fontWeight: '$bold',
  color: '$primary',
  textDecoration: 'none',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid $white',
  padding: 4,
  transition: 'all 0.15s ease-in-out',
  '&:hover': {
    borderBottom: '1px solid $primary',
  },
  '&:focus': {
    outlineColor: '#DCD4FF',
    outlineStyle: 'solid',
    outlineWidth: 'medium',
  },
});

export const styles = {
  mainClass: css({
    backgroundImage: `url("${staticFilePaths.authBgWEBP}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    // height: '100%',
    alignItems: 'center',
    backgroundPosition: 'center',
    py: 64,
    px: 16,
  }),
  inputCss: { width: 'auto', '@md': { width: '70%', mx: 'auto' } },
};
export const ALinkTerm = styled('a', {
  ...typography.body4,
  fontWeight: '600',
  color: '$primary3',
  textDecoration: 'none',
  '&:hover': {
    color: '$primary2',
  },
});

export const LoginContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',

  '@md': {
    flexDirection: 'row',
    gap: 8,
  },
});

export const AuthContainer = styled('div', {
  minHeight: 'calc(100vh - 72px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 16,
});

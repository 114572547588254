import { useLogin } from 'api-hooks/auth/mutation';
import { getMeKey, useGetMe } from 'api-hooks/auth/query';
import NavigationEnum from 'common/navigation';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/commons/separator';
import Text from 'components/commons/text';
import Input from 'components/elements/field';
import Form from 'components/elements/form';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import * as Yup from 'yup';

import AuthBackground from '../components/auth-background';
import {
  ALinkMenu,
  AuthContainer,
  CardContainer,
  LoginContainer,
} from '../style';

type FormType = {
  username: string;
  password: string;
};

interface Props {
  redirect?: string;
}

export default function LoginCard(props: Props) {
  const { t } = useTranslation();
  const router = useRouter();
  const toast = useToasts();
  const { mutateAsync } = useLogin();

  const enabled = React.useMemo(() => {
    try {
      return !!localStorage?.getItem('credential');
    } catch (e) {
      return false;
    } finally {
    }
  }, []);

  const { refetch } = useGetMe({
    enabled,
  });

  const yupSchema = React.useMemo(
    () =>
      Yup.object()
        .shape({
          username: Yup.string().email().required(),
          password: Yup.string().min(8).required(),
        })
        .required(),
    [],
  );

  const resolver = useYupValidationResolver(yupSchema);
  const methods = useForm<FormType>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver,
    mode: 'all',
  });
  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const result = await mutateAsync(values, {
          onSuccess: (data) => {
            queryClient.setQueryData(getMeKey(), data.data);
          },
        });

        localStorage.setItem('credential', JSON.stringify(result.data));

        refetch();
        global.refreshToken = result?.data?.refreshToken;

        router.push(props.redirect || NavigationEnum.home);
        if (props.redirect) {
          sessionStorage?.setItem('prevUrl', '');
        }
      } catch (error) {
        toast.addToast(t('common:invalid_credential'), {
          appearance: 'error',
          autoDismiss: true,
        });
      } finally {
      }
    },
    [mutateAsync, refetch, router, props.redirect, toast, t],
  );

  return (
    <AuthContainer>
      <AuthBackground />
      <CardContainer>
        <Text variant="headline3" weight="bold" align="center">
          {t('common:login_account')}
        </Text>
        <Separator gap={16} />
        <Text variant="body3" align="center">
          {t('common:login_account_message')}
        </Text>
        <Separator gap={24} />
        <Form methods={methods} onSubmit={onSubmit} autoComplete="off">
          <Input
            type="email"
            name="username"
            label={t('common:email_address')}
            placeholder={t('common:input_email_address')}
            autoComplete="email-off"
          />
          <Separator gap={16} />
          <Input
            type="password"
            name="password"
            label={t('common:password')}
            placeholder={t('common:input_password')}
            autoComplete="password-off"
          />
          <Separator gap={16} />
          <Link passHref href={NavigationEnum.forgotPassword}>
            <ALinkMenu
              style={{
                justifySelf: 'flex-end',
                width: 'fit-content',
              }}
            >
              {t('common:forgot_password')}?
            </ALinkMenu>
          </Link>
          <Separator gap={16} />
          <Input
            type="submit"
            text={t('common:login')}
            css={{ width: '100%' }}
          />
          <Separator gap={16} />
          <LoginContainer>
            <Text variant="body3">{t('common:dont_have_an_account_yet')}</Text>
            <Link href={NavigationEnum.register} passHref>
              <ALinkMenu href={NavigationEnum.register}>
                {t('common:sign_up_now')}
              </ALinkMenu>
            </Link>
          </LoginContainer>
        </Form>
      </CardContainer>
    </AuthContainer>
  );
}

import staticFilePaths from 'constant/static-file';

export default function AuthBackground() {
  return (
    <div
      style={{
        backgroundImage: `url("${staticFilePaths.authBgWEBP}")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'fixed',
        top: 44,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
      }}
    />
  );
}
